import { ReactNode } from "react";
import {
  Box,
  Container,
  Stack,
  useColorModeValue,
  Text,
  Button,
  VisuallyHidden,
  Link,
  Center,
  Image,
} from "@chakra-ui/react";
import { FaDiscord, FaTwitter } from "react-icons/fa";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <Button
      bg="whiteAlpha.100"
      rounded={"full"}
      w="12"
      h="12"
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  );
};

function Footer() {
  return (
    <Box pt="5rem">
      <Box minH="12rem" bg="blackAlpha.500" borderTop="2px" borderColor="white">
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          spacing={4}
          justify={"center"}
          align={"center"}
        >
          <Link href="/">
            <Center>
              <Text fontWeight={"normal"} fontSize={"4xl"}>
                🎄
              </Text>
            </Center>
            <Text fontWeight="semibold">NFTPostcards.xyz</Text>
          </Link>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Twitter"}
              href={"https://twitter.com/NFT_Postcards"}
            >
              <FaTwitter />
            </SocialButton>
            <SocialButton
              label={"Discord"}
              href={"https://discord.gg/R4WqEcDmxJ"}
            >
              <FaDiscord />
            </SocialButton>
            <SocialButton
              label={"OpenSea"}
              href={"https://opensea.io/collection/nft-postcards-collection"}
            >
              <Image src="./images/opensea_white.png" />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
