import { useState, useEffect } from "react";
import {
  Center,
  Container,
  Image,
  Heading,
  Box,
  Button,
  Text,
  VStack,
  HStack,
  Input,
  Stack,
  Link,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import Snowfall from "react-snowfall";
import { DateTime } from "luxon";
import { ethers, Contract, BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";
import ConnectWallet from "./ConnectWallet";
import nftpostcardsABI from "../../abi/nftpostcardsABI.json";
import { Web3Provider, Signer } from "../../types";

function Body() {
  const toast = useToast();

  const mintEndTime = DateTime.fromSeconds(1640494800);

  const nftpostcardsAddress = "0xbB72B15Cea3Ab9B343Da260c6b5dF89a8F6fD5D2";

  const getTimeLeft = () => {
    const now = DateTime.now();
    const _diff = mintEndTime.diff(now, ["hours", "minutes", "seconds"]);
    const diff = _diff.toObject();

    if (_diff.toMillis() < 0) {
      return `00H:00M:00S`;
    }

    let hours = diff.hours?.toString();
    let minutes = diff.minutes?.toFixed();
    let seconds = diff.seconds?.toFixed();

    if (hours?.length === 1) {
      hours = "0" + hours;
    }
    if (minutes?.length === 1) {
      minutes = "0" + minutes;
    }
    if (seconds?.length === 1) {
      seconds = "0" + seconds;
    }

    return `${hours}:${minutes}:${seconds}`;
  };

  const [timer, setTimer] = useState(getTimeLeft());
  const [inpQty, setInpQty] = useState<string>("1");
  const [qty, setQty] = useState(1);
  const [remaining, setRemaining] = useState(1225);
  const [resRemaining, setResRemaining] = useState(0);

  const [infuraProvider, setInfuraProvider] =
    useState<ethers.providers.JsonRpcProvider>();
  const [provider, setProvider] = useState<Web3Provider>();
  const [signer, setSigner] = useState<Signer>();
  const [signerAddress, setSignerAddress] = useState<string>();

  const [contract, setContract] = useState<Contract>();

  useEffect(() => {
    setInterval(() => {
      setTimer(getTimeLeft());
    }, 1000);

    setInfuraProvider(
      new ethers.providers.JsonRpcProvider(
        `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
      )
    );
  }, []);

  useEffect(() => {
    (async () => {
      if (infuraProvider) {
        const _contract = new ethers.Contract(
          nftpostcardsAddress,
          nftpostcardsABI,
          infuraProvider
        );
        const {
          totalSupply,
          reservedRemaining,
        }: { totalSupply: BigNumber; reservedRemaining: BigNumber } =
          await _contract.tokenInfo(1);
        setRemaining(
          1225 - totalSupply.toNumber() - reservedRemaining.toNumber()
        );
      }
    })();
  }, [infuraProvider]);

  useEffect(() => {
    if (provider) {
      setContract(
        new ethers.Contract(nftpostcardsAddress, nftpostcardsABI, provider)
      );

      setSigner(provider.getSigner(0));
    }
  }, [provider]);

  // Get signer address
  useEffect(() => {
    const getSignerAddress = async () => {
      if (signer) {
        setSignerAddress(await signer.getAddress());
      }
    };

    getSignerAddress();
  }, [signer]);

  const fetchTotalSupply = async () => {
    const {
      totalSupply,
      reservedRemaining,
    }: { totalSupply: BigNumber; reservedRemaining: BigNumber } =
      await contract!.tokenInfo(1);
    setRemaining(1225 - totalSupply.toNumber() - reservedRemaining.toNumber());
  };

  useEffect(() => {
    if (contract) {
      fetchTotalSupply();
    }
  }, [contract]);

  useEffect(() => {
    setQty(inpQty ? parseInt(inpQty) : 0);
  }, [inpQty]);

  const mintNFT = async () => {
    if (qty > remaining) {
      toast({
        title: "Error",
        description: "Max Supply Exceeded",
        status: "error",
        isClosable: true,
        duration: 4000,
      });
    } else {
      try {
        await contract!.connect(signer!).mint(1, qty, {
          value: parseEther((0.01 * qty).toString()),
        });
        fetchTotalSupply();
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      }
    }
  };

  return (
    <Container pt="2rem" maxW="60rem">
      <Snowfall snowflakeCount={100} />
      <Stack
        mt="2rem"
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "center", md: "stretch" }}
        mx={4}
        spacing={5}
        justifyContent="space-between"
      >
        <Box flex={1} maxW="34rem">
          <Center>
            <Box pt="2rem" pr="4rem">
              <Heading>#1 XMAS'21 Postcard</Heading>
              <Link
                href="https://opensea.io/assets/0xbB72B15Cea3Ab9B343Da260c6b5dF89a8F6fD5D2/1"
                isExternal
              >
                <Center>
                  <Text fontWeight={"bold"}>View on OpenSea</Text>
                  <Image pl="0.5rem" src="./images/opensea.png" w="2.5rem" />
                </Center>
              </Link>
              <Center pt="1rem">
                <VStack>
                  <Heading pt="1rem" fontSize={"2xl"}>
                    <HStack>
                      {timer !== "00H:00M:00S" ? (
                        <>
                          {remaining === 1225 ? (
                            <Skeleton
                              startColor="#011629"
                              endColor="grey"
                              rounded="xl"
                            >
                              1225
                            </Skeleton>
                          ) : (
                            <Text>{remaining}</Text>
                          )}
                          <Text>Remaining</Text>
                        </>
                      ) : (
                        <Text>Minting Ended</Text>
                      )}
                    </HStack>
                  </Heading>
                  <Center
                    py="1rem"
                    px="4rem"
                    border="1px solid white"
                    rounded="xl"
                    zIndex={2}
                    bg="#011629"
                  >
                    <VStack>
                      <Text fontWeight={"bold"}>Quantity to mint:</Text>
                      <HStack w="100%">
                        <Button
                          w="100%"
                          onClick={() => {
                            const newQty = qty + 1;
                            setQty(newQty);
                            setInpQty(newQty.toString());
                          }}
                        >
                          +1
                        </Button>
                        <Button
                          w="100%"
                          onClick={() => {
                            const newQty = qty + 5;
                            setQty(newQty);
                            setInpQty(newQty.toString());
                          }}
                        >
                          +5
                        </Button>
                        <Button
                          w="100%"
                          onClick={() => {
                            const newQty = qty + 10;
                            setQty(newQty);
                            setInpQty(newQty.toString());
                          }}
                        >
                          +10
                        </Button>
                      </HStack>
                      <Box pt="0.5rem">
                        <Input
                          type="number"
                          placeholder="Quantity"
                          borderColor={"#52c234"}
                          value={inpQty}
                          onChange={(e) => {
                            setInpQty(e.target.value);
                          }}
                        />
                      </Box>
                      <Box pt="1rem">
                        <Box
                          pos={"relative"}
                          p="0.2rem"
                          background={"linear-gradient(to right, #52c234, red)"}
                          rounded={"md"}
                        >
                          {signer ? (
                            <Button
                              onClick={() => {
                                mintNFT();
                              }}
                              fontSize={"xl"}
                              p="1.5rem"
                              background={"black"}
                              _hover={{
                                background: "transparent",
                              }}
                              isDisabled={
                                remaining === 0 || timer === "00H:00M:00S"
                              }
                            >
                              {remaining === 0
                                ? "Sold Out"
                                : `Mint ${qty} NFT${qty > 1 ? "s" : ""}`}
                            </Button>
                          ) : (
                            <ConnectWallet setProvider={setProvider} />
                          )}
                        </Box>
                      </Box>
                      <Text>@ 0.01 ETH / NFT</Text>
                    </VStack>
                  </Center>
                  <Heading pt="1rem" fontSize={"2xl"}>
                    {timer !== "00H:00M:00S" ? <>Time Left: {timer}</> : ""}
                  </Heading>
                </VStack>
              </Center>
            </Box>
          </Center>
        </Box>
        <Box flex={1} pt="2rem" maxW="34rem" w="100%">
          <Image
            src="./images/xmas-nft.gif"
            border={"2px solid white"}
            rounded="md"
            zIndex={2}
          />
          <Text fontStyle={"italic"} pt="1rem" textAlign={"center"}>
            Perfect NFT to gift to Family and Friends 🎁
          </Text>
        </Box>
      </Stack>
      <Center pt="3rem">
        <VStack>
          <Heading>About the Collection</Heading>
          <Text fontSize={"xl"} fontWeight={"semibold"}>
            Dynamic NFTs that change view based on the time you are looking at!
          </Text>
        </VStack>
      </Center>
      <Stack
        mt="2rem"
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "center", md: "stretch" }}
        mx={4}
        spacing={5}
        justifyContent="space-between"
      >
        <Box flex={1} maxW="34rem" pb="2rem" rounded="1rem">
          <Center pt={4} px={6}>
            <VStack spacing={4}>
              <Box>
                <Heading as="h2" fontWeight="semibold">
                  During Day
                </Heading>
                <Text whiteSpace="pre-wrap" textAlign="center">
                  (6am - 6pm)
                </Text>
              </Box>
              <Image
                src="./images/day.png"
                border={"1px solid white"}
                rounded="md"
                zIndex={2}
              />
            </VStack>
          </Center>
        </Box>
        <Box flex={1} maxW="34rem" pb="2rem" rounded="1rem">
          <Center pt={4} px={6}>
            <VStack spacing={4}>
              <Box>
                <Heading as="h2" fontWeight="semibold">
                  During Night
                </Heading>
                <Text whiteSpace="pre-wrap" textAlign="center">
                  (6pm - 6am)
                </Text>
              </Box>
              <Image
                src="./images/night.png"
                border={"1px solid white"}
                rounded="md"
                zIndex={2}
              />
            </VStack>
          </Center>
        </Box>
      </Stack>
      <Center pt="1rem">
        <Text fontSize={"xl"} fontWeight={"semibold"}>
          Some components of the NFT are randomly generated on each reload as
          well.
        </Text>
      </Center>
    </Container>
  );
}

export default Body;
