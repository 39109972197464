import { Flex, Heading, Spacer, Text, HStack } from "@chakra-ui/react";

function Navbar() {
  return (
    <Flex
      py="4"
      px={["2", "4", "10", "10"]}
      borderBottom="2px"
      borderBottomColor="gray.400"
    >
      <Spacer flex="1" />
      <Heading maxW={["302px", "4xl", "4xl", "4xl"]}>
        <HStack>
          <Text fontWeight={"normal"}>🎄</Text>
          <Text fontWeight={"extrabold"}>NFT Postcards</Text>
          <Text fontWeight={"normal"}>🎄</Text>
        </HStack>
      </Heading>
      <Spacer flex="1" />
    </Flex>
  );
}

export default Navbar;
