import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const bgColor = "#011629";
const theme = extendTheme({
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode(bgColor, bgColor)(props),
      },
    }),
  },
  config,
});

export default theme;
